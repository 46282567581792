#contact {
  padding-top: 10%;
  padding-bottom: 20px;
  /* height: 100%; */
  background-image: url("../../assets/images/contact-us-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
}
.input-contain {
  position: relative;
  margin-top: 5%;
}
