.navbar {
  background-color: #000;
  color: #fff;
  padding: 0.1rem 1rem;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link:hover {
  color: #cea33e;
}

.navbar .navbar-heading {
  font-size: 1.7rem;
  width: 100%;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
  .navbar-custom {
    background-color: #000;
  }

  .navbar-light a img {
    width: 70px;
    /* padding: 2rem; */
  }

  .navbar .navbar-heading {
    font-size: 1.2rem;
    width: 50%;
  }

  #home .container .row {
    margin-top: 0;
  }
}
