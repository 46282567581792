#footer {
  position: relative;
  /* margin: 0; */
  /* padding: 0; */
  /* margin-top: 40px; */
  color: #fff;
}

#footer div {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #21201e;
}

#footer .footer-container {
  font-size: 0.77rem;
  text-align: right;
  padding-top: 13px;
  padding-right: 12px;
}

#footer .footer-container a,
#footer .footer-container a:hover {
  color: rgb(15, 96, 163);
}

@media screen and (max-width: 768px) {
  #footer .footer-container {
    text-align: left;
    padding-left: 12px;
  }
}
