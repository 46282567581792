#home {
  position: relative;
  background-image: url("../../assets/images/home_bg_1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  padding-top: 12%;
  overflow-x: hidden;
  color: #fff;
}

#home img {
  max-width: 450px;
  width: 100%;
}

.navbar {
  background-color: #fff;
  color: #000;
  opacity: 0.85;
}

.navbar .navbar-heading {
  font-size: 1.4rem;
  width: 15%;
  display: block;
  /* text-align: center; */
  line-height: 120%;
  letter-spacing: 5px;
  font-weight: bold;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.color-primary{
  color: rgb(41,70,45);
}

.color-secondary{
  color: rgb(169,186,65);
  font-size: 1.2rem;
  margin-left: 15%;
}

.learn_more {
  text-align: center;
  margin-top: 20%;
}

@media screen and (max-width: 768px) {
  #home {
    padding-top: 30%;
  }

  .color-primary{
    margin-left: 3%;
  }
  

  .color-secondary{
    margin-left: 10%;
  }

  .learn_more {
    margin-top: 10%;
  }

  .navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 0;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

  .navbar .navbar-heading {
    font-size: 1.4rem;
    width: 80.9%;
    margin:0;
    /* text-align: center; */
  }
}


@media screen and (max-width: 398px) {
  .navbar .navbar-heading {
    font-size: 1.4rem;
    width: 70%;
    /* text-align: center; */
  }
}
