html, body {
  max-width: 100%;
}

html, body, #root, .App {
    height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
