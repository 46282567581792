/** Generated by FG **/
@font-face {
  font-family: "HurmeGeometricSans2";
  src: url("assets/fonts/HurmeGeometricSans2.eot");
  src: local("☺"), url("assets/fonts/HurmeGeometricSans2.woff") format("woff"),
    url("assets/fonts/HurmeGeometricSans2.ttf") format("truetype"),
    url("assets/fonts/HurmeGeometricSans2.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HurmeGeometricSans2 Light";
  src: url("assets/fonts/HurmeGeometricSans2 Light.eot");
  src: local("☺"),
    url("assets/fonts/HurmeGeometricSans2 Light.woff") format("woff"),
    url("assets/fonts/HurmeGeometricSans2 Light.ttf") format("truetype"),
    url("assets/fonts/HurmeGeometricSans2 Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HurmeGeometricSans2 SemiBold";
  src: url("assets/fonts/HurmeGeometricSans2 SemiBold.eot");
  src: local("☺"),
    url("assets/fonts/HurmeGeometricSans2 SemiBold.woff") format("woff"),
    url("assets/fonts/HurmeGeometricSans2 SemiBold.ttf") format("truetype"),
    url("assets/fonts/HurmeGeometricSans2 SemiBold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HurmeGeometricSans2 Bold";
  src: url("assets/fonts/HurmeGeometricSans2 Bold.eot");
  src: local("☺"),
    url("assets/fonts/HurmeGeometricSans2 Bold.woff") format("woff"),
    url("assets/fonts/HurmeGeometricSans2 Bold.ttf") format("truetype"),
    url("assets/fonts/HurmeGeometricSans2 Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "HurmeGeometricSans2";
  /* color: #010101; */
  color: #434343;
}
a {
  color: inherit;
}
a:hover {
  color: #cea33e;
  text-decoration: none;
}
.font-regular {
  font-family: "HurmeGeometricSans2";
}
.font-light {
  font-family: "HurmeGeometricSans2 Light";
}
.font-semi-bold {
  font-family: "HurmeGeometricSans2 SemiBold";
}
.font-bold {
  font-family: "HurmeGeometricSans2 Bold";
}
.title-color {
  color: #cea33e;
}

h1 {
  font-family: "HurmeGeometricSans2";
  font-size: 3.3rem;
}
h2 {
  font-family: "HurmeGeometricSans2 SemiBold";
  font-size: 2.8rem;
}

#footer {
  /* padding-top: 3%; */
}

.top-content {
  margin-top: 180px;
}

.top-content-150 {
  margin-top: 150px;
}

.top-content-140 {
  margin-top: 140px;
}

.top-content-130 {
  margin-top: 130px;
}

.top-content-100 {
  margin-top: 100px;
}

.top-content-90 {
  margin-top: 90px;
}

.top-content-80 {
  margin-top: 80px;
}

.top-content-70 {
  margin-top: 70px;
}

.top-content-60 {
  margin-top: 60px;
}

.top-content-50 {
  margin-top: 50px;
}

.top-content-40 {
  margin-top: 40px;
}

.top-content-30 {
  margin-top: 30px;
}

.top-content-20 {
  margin-top: 20px;
}

.top-content-10 {
  margin-top: 10px;
}
