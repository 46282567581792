#products {
  /* height: 100%; */
  color: #fff;
  padding-top: 10%;
  /* background-image: url("../../assets/images/"); */
  background-color: #303030;
  background-repeat: no-repeat;
  background-position: right;
  text-align: center;
}

.product-item {
  padding-bottom: 5%;
}

.product-heading {
  text-align: left;
  padding-bottom: 1%;
}

.product-price {
}

.product-image img {
  width: 100%;
  max-width: 350px;
}

.place-order {
  padding-bottom: 3%;
}

.product-text {
  font-size: 1.25rem;
  text-align: left;
}

.product-checkbox {
  /* text-align: center; */
}

input[type="checkbox"] {
  background-color: rgb(170, 194, 48);
}

@media screen and (max-width: 768px) {
  #products {
    /* height: auto; */
    padding-top: 30%;
  }
}
