#testimonials,
#all-testimonials {
  /* height: 100%; */
  padding-top: 10%;
  padding-bottom: 2%;
  /* background-image: url("../../assets/images/values-bg.jpg");
  background-repeat: no-repeat;
  background-position: center; */
  background-color: #000;
  color: #fff;
}

#all-testimonials {
  height: auto;
}

.testimonial_image {
  width: 100%;
  max-width: 350px;
}

.testimonial-text {
  font-size: 1.25rem;
}

.slide-container {
  height: 100%;
}

@media screen and (max-width: 768px) {
  #testimonials {
    height: auto;
    padding-top: 8%;
  }

  #all-testimonials {
    padding-top: 25%;
  }

  .testimonial-text {
    font-size: 1.25rem;
    padding-top: 2%;
    padding-bottom: 5%;
    text-align: center;
  }

  #testimonials a .gh_btn_secondary {
    text-align: center;
  }
}
