.mdc-text-field:not(.mdc-text-field--disabled) {
  background-color: rgba(0, 0, 0, 0);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgb(170, 194, 48);
}

.mdc-text-field .mdc-text-field__input {
  caret-color: rgb(170, 194, 48);
  caret-color: var(--mdc-theme-primary, rgb(170, 194, 48));
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgb(170, 194, 48);
}

.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
  .mdc-text-field__input:hover {
  border-bottom-color: #cea33e;
}

.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
  .mdc-text-field__input {
  border-bottom-color: rgba(170, 194, 48, 0.42);
}

.mdc-text-field {
  width: 100%;
  border-bottom-color: rgba(170, 194, 48 0.12);
}

.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: rgba(170, 194, 48 0.12);
}

/**********************************************/

.mdc-text-field .mdc-text-field__input {
  caret-color: #cea33e;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #cea33e);
}

.mdc-text-field .mdc-line-ripple {
  background-color: #cea33e;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #cea33e);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: #cea33e;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #cea33e);
}

.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: #cea33e;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #cea33e);
}

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #cea33e;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #cea33e);
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined {
  color: #cea33e;
}


/***************************************************************/
.MuiTextField-root,
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root,
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root,
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  border-bottom-color: rgb(170, 194, 48) !important; 
  color:rgb(170, 194, 48) !important;
  font-size: 1.0rem !important;
}

#contact > div > div.row.mt-3 > div.contact-form.col-md-6.col-sm-12 > form > div:nth-child(2) > div:nth-child(1) > div.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root > div > input{
  border-bottom-color: rgb(170, 194, 48) !important; 
  color:rgb(170, 194, 48) !important; 
}



.css-mnn31, 
.css-348fk2,
.css-mqt2s5,
.css-1wt0ykv,
.css-348fk2.Mui-focused {
  color:rgb(170, 194, 48) !important;
}

/* .MuiFormControl-root.MuiInputLabel-root {
  color:rgba(170, 194, 48, 0.42) !important;
}

.MuiSlider-root {
  color: greenyellow !important;
}

.MuiSlider-root:hover {
  color: greenyellow !important;
} */