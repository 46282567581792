#main-wrapper {
  height: 100%;
}

h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 4rem;
  margin: auto;
}

.center {
  text-align: center;
}

.gh_green_1 {
  color: rgb(2, 70, 42);
}

.gh_green_2 {
  color: rgb(170, 194, 48);
}

.gh_green_3 {
  color: rgb(182, 215, 121);
}

.gh_btn_default {
  background-color: rgb(152, 152, 152, 0.8);
  border: none;
  border-radius: 3px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  padding: 15px 10%;
}

.gh_btn_secondary {
  background-color: rgba(170, 194, 48, 0);
  border: solid;
  border-radius: 3px;
  color: rgb(170, 194, 48);
  font-weight: bold;
  padding: 15px 10%;
}

.gh_btn_secondary_2 {
  background-color: rgba(170, 194, 48, 0);
  border: solid;
  border-radius: 3px;
  color: rgb(170, 194, 48);
  font-weight: bold;
  padding: 8px 3%;
}

.gh_btn_secondary:hover {
  color: #cea33e;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 2.5rem;
    text-align: center;
  }

  h3 {
    font-size: 1.5rem;
  }

  .submit-btn {
    text-align: center;
  }
}
