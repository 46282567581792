#learn-more {
  position: relative;
  background-image: url("../../assets/images/kimzy-nanney-b2haCjfk_cM-unsplash.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  padding-top: 12%;
  overflow-x: hidden;
  color: #fff;
}

#learn-more img {
  max-width: 450px;
  width: 100%;
}

.learn_more {
  text-align: center;
  margin-top: 20%;
}

@media screen and (max-width: 768px) {
  #learn-more {
    padding-top: 30%;
  }

  .learn_more {
    margin-top: 10%;
  }
}
